let FilePath:string  = '';
if (document.location.host.indexOf('localhost')>=0){
    // FilePath = `${process.env.REACT_APP_BASE_URL_LOCAL_PROD}`
    FilePath = `${process.env.REACT_APP_BASE_URL_DEV}`
}else if (document.location.host.indexOf('192.168')>=0){
    FilePath = `${process.env.REACT_APP_BASE_URL_LOCAL_PROD}`
}else if (document.location.protocol.indexOf('https')>=0){
    FilePath = `${process.env.REACT_APP_BASE_URL}`
// }else if (document.location.host.indexOf('helpdesk')>=0){
//     FilePath = `${process.env.REACT_APP_BASE_URL}`
}else{
    FilePath = `${process.env.REACT_APP_BASE_URL_LOCAL_PUBLIC_PROD}`
}
export { FilePath }