/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useEffect, useState,
  // useRef
} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {registerPengadaan} from '../redux/AuthCRUD'
// import ReCAPTCHA from "react-google-recaptcha"
import {FetchProvinsi, FetchKota} from "../../store/actions/general/RegionalNoAuthAction"
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FetchKompetensiBidangUsahaNoAuth } from '../../store/actions/procurement/PengadaanAction'
// import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const company_id = process.env.REACT_APP_BASE_COMPANY_ID || '1'
const app_type = process.env.REACT_APP_BASE_APPLICATION_TYPE_RECRUITMENT || '3'
const initialValues = {
  userid: '',
  company_id: company_id, //hardcode
  nipp: '',
  application_type: app_type,
  nama: '',
  deskripsi: '',
  telp_1: '',
  telp_2: '',
  alamat: '',
  kode_pos: '',
  nomor_fax: '',
  base_propinsi_id: '',
  base_kota_id: '',
  base_kompetensi_bidang_usaha_id: '',
  site: '',
  email: '',
  password: '',
  password_confirmation: '',
  acceptTerms: false,
  // recaptcha: '',
}

const registrationSchema = Yup.object().shape({
  nama: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  deskripsi: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Description is required'),
  userid: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  telp_1: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(14, 'Maximum 14 symbols')
    .required('Telepon is required'),
  telp_2: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(14, 'Maximum 14 symbols'),
  alamat: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Alamat is required'),
  // site: Yup.string()
  //   .url('Wrong url format')
  //   .min(3, 'Minimum 3 symbols'),
  base_propinsi_id: Yup.string()
    .required('Provinsi is required'),
  base_kota_id: Yup.string()
    .required('Kota is required'),
  base_kompetensi_bidang_usaha_id: Yup.string()
    .required('Kompetensi Bidang Usaha is required'),
  kode_pos: Yup.string()
    .min(5, 'Minimum 5 symbols')
    .max(5, 'Maximum 5 symbols')
    .required('Kode Pos is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  // recaptcha: Yup.string().required('Chaptcha is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function RegistrationPengadaan(props:any) {
  // const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false)
  const [statusApi, setStatusApi] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const [errors, setErrors] = useState<any>({})

  const [provinsi, setProvinsi] = useState([]);
  const [loadingProvinsi, setLoadingProvinsi] = useState(true);
  const [showKota, setShowKota] = useState(false);
  const [kota, setKota] = useState([]);
  const [loadingKota, setLoadingKota] = useState(true);
  const [kompetensi, setKompetensi] = useState([]);
  const [loadingKompetensi, setLoadingKompetensi] = useState(true);

  const handleChangeProvinsi = (selectedOption:any) => {
    // console.log(selectedOption)
    if (selectedOption === null) {
      formik.setFieldValue('base_propinsi_id', '')
      setShowKota(false)
    }
    else {
      formik.setFieldValue('base_propinsi_id', selectedOption.value)
      setShowKota(true)
      fetchKota(selectedOption.value)
    }
  }
  
  const handleChangeKota = (selectedOption:any) => {
    formik.setFieldValue('base_kota_id', selectedOption === null ? "" : selectedOption.value)
  }

  const handleChangeKompetensi = (selectedOption:any) => {
    formik.setFieldValue('base_kompetensi_bidang_usaha_id', selectedOption === null ? "" : selectedOption.value)
  }

  // GET KOTA
  const fetchKota = useCallback((id:any) => {
    setLoadingKota(true)
    const params = {
      company_id: initialValues.company_id,
      // company_id: company_id,
      base_propinsi_id: id,
    }
    FetchKota(params)
      .then((resp:any) => {
        let data = resp.data.data
        data.forEach((_element: any, index:any) => {
          data[index].label = _element.nama
          data[index].value = _element.id
        });
        setKota(data)
        setLoadingKota(false)
      })
      .catch((err:any) => {
        console.log(err)
        setKota([])
        setLoadingKota(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // GET PROVINSI
  useEffect(() => {
    const params = {
      company_id: initialValues.company_id
      // company_id: company_id,
    }
    FetchProvinsi(params)
      .then((resp:any) => {
        const data = resp.data.data
        data.forEach((_element: any, index:any) => {
          data[index].label = _element.nama
          data[index].value = _element.id
        });
        setProvinsi(data)
        setLoadingProvinsi(false)
      })
      .catch((err:any) => {
        console.log(err)
        setProvinsi([])
      })
  }, []);

  // GET KOMPETENSI BIDANG USAHA
  useEffect(() => {
    const params = {
      company_id: initialValues.company_id
      // company_id: company_id,
    }
    FetchKompetensiBidangUsahaNoAuth(params)
      .then((resp:any) => {
        const data = resp.data.data
        data.forEach((_element: any, index:any) => {
          data[index].label = _element.nama
          data[index].value = _element.id
        });
        setKompetensi(data)
        setLoadingKompetensi(false)
      })
      .catch((err:any) => {
        console.log(err)
        setKompetensi([])
      })
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setErrors([])
      // const token = recaptchaRef.current?.getValue();
      // recaptchaRef.current?.reset();
      setLoading(true)
      setStatusApi(false)
      setTimeout(() => {
        registerPengadaan(values.userid, values.company_id, values.nipp, values.application_type,
          values.nama, values.deskripsi, values.telp_1, values.telp_2, values.base_propinsi_id, values.base_kota_id,
          values.alamat, values.kode_pos, values.nomor_fax, values.site, values.email, values.base_kompetensi_bidang_usaha_id,
          values.password, values.password_confirmation
          // , values.recaptcha
          )
          .then(() => {
            setLoading(false)
            
            setStatusApi(true)
            setStatusMessage("Register Sukses")
            setStatusColor("success")
            toast.success("Registrasi Sukses !", {position: toast.POSITION.TOP_RIGHT});
            setTimeout(() => {
              props.setTabOpen('login')
            },
            2000)
          })
          .catch((err) => {
            console.log(err.data.message)
            setLoading(false)
            setSubmitting(false)
            setStatus(err.data.message)
            
            setStatusApi(true)
            setStatusMessage(err.data.message)
            setErrors(JSON.parse(err.data.message))
            // setStatusMessage(err.data.message)
            setStatusColor('danger')
          })
      }, 1000)
    },
  })

  // const handleChangeCaptcha = (value:any) => {
  //   // console.log("Captcha value:", value);
  //   formik.setFieldValue('recaptcha', value)
  // };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Buat Akun Baru</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Sudah punya akun ?{' '}
          <button type="button" className='btn btn-link p-0 link-primary fw-bolder' onClick={() => props.setTabOpen('login')}>Login</button>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {statusApi && (
        <div className={'mb-lg-4 alert alert-'+ statusColor}>
          <div className='alert-text font-weight-bold'>{statusMessage}</div>
        </div>
      )}


      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Nama Perusahaan <span className="text-danger">*</span></label>
        <input
          placeholder='Nama'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('nama')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.nama && formik.errors.nama,
            },
            {
              'is-valid': formik.touched.nama && !formik.errors.nama,
            }
          )}
        />
        {formik.touched.nama && formik.errors.nama && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.nama}</span>
            </div>
          </div>
        )}
        {errors?.nama && <span className="text-danger">{errors?.nama}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Profil Perusahaan <span className="text-danger">*</span></label>
        <textarea
          placeholder='Deskripsi'
          rows={3}
          autoComplete='off'
          {...formik.getFieldProps('deskripsi')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.deskripsi && formik.errors.deskripsi,
            },
            {
              'is-valid': formik.touched.deskripsi && !formik.errors.deskripsi,
            }
          )}
        />
        {formik.touched.deskripsi && formik.errors.deskripsi && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.deskripsi}</span>
            </div>
          </div>
        )}
        {errors?.deskripsi && <span className="text-danger">{errors?.deskripsi}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Telepon 1 <span className="text-danger">*</span></label>
        <input
          placeholder='Telepon 1'
          autoComplete='off'
          {...formik.getFieldProps('telp_1')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.telp_1 && formik.errors.telp_1,
            },
            {
              'is-valid': formik.touched.telp_1 && !formik.errors.telp_1,
            }
          )}
        />
        {formik.touched.telp_1 && formik.errors.telp_1 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.telp_1}</span>
            </div>
          </div>
        )}
        {errors?.telp_1 && <span className="text-danger">{errors?.telp_1}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Telepon 2</label>
        <input
          placeholder='Telepon 2'
          autoComplete='off'
          {...formik.getFieldProps('telp_2')}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
        {errors?.telp_2 && <span className="text-danger">{errors?.telp_2}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Provinsi <span className="text-danger">*</span></label>
        <div>
          <Select
            options={provinsi}
            isLoading={loadingProvinsi}
            isClearable
            name={"provinsi"}
            onChange={handleChangeProvinsi}
          />
        </div>
        {formik.touched.base_propinsi_id && formik.errors.base_propinsi_id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.base_propinsi_id}</span>
            </div>
          </div>
        )}
        {errors?.base_propinsi_id && <span className="text-danger">{errors?.base_propinsi_id}</span>}
      </div>

      {showKota &&
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Kota <span className="text-danger">*</span></label>
          <div>
            <Select
              options={kota}
              isLoading={loadingKota}
              isClearable
              name={"kota"}
              onChange={handleChangeKota}
            />
          </div>
          {formik.touched.base_kota_id && formik.errors.base_kota_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.base_kota_id}</span>
              </div>
            </div>
          )}
          {errors?.base_kota_id && <span className="text-danger">{errors?.base_kota_id}</span>}
        </div>
      }

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Alamat <span className="text-danger">*</span></label>
        <textarea
          placeholder='Alamat'
          rows={3}
          autoComplete='off'
          {...formik.getFieldProps('alamat')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.alamat && formik.errors.alamat,
            },
            {
              'is-valid': formik.touched.alamat && !formik.errors.alamat,
            }
          )}
        />
        {formik.touched.alamat && formik.errors.alamat && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.alamat}</span>
            </div>
          </div>
        )}
        {errors?.alamat && <span className="text-danger">{errors?.alamat}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Kode Pos <span className="text-danger">*</span></label>
        <input
          placeholder='Kode Pos'
          type="number"
          onWheel={e => e.currentTarget.blur()}
          autoComplete='off'
          {...formik.getFieldProps('kode_pos')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.kode_pos && formik.errors.kode_pos,
            },
            {
              'is-valid': formik.touched.kode_pos && !formik.errors.kode_pos,
            }
          )}
        />
        {formik.touched.kode_pos && formik.errors.kode_pos && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.kode_pos}</span>
            </div>
          </div>
        )}
        {errors?.kode_pos && <span className="text-danger">{errors?.kode_pos}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Nomor Fax</label>
        <input
          placeholder='Nomor Fax'
          autoComplete='off'
          {...formik.getFieldProps('nomor_fax')}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
        {errors?.nomor_fax && <span className="text-danger">{errors?.nomor_fax}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Website Resmi Perusahaan</label>
        <input
          placeholder='http://google.com'
          type='url'
          autoComplete='off'
          {...formik.getFieldProps('site')}
          className={clsx('form-control form-control-lg form-control-solid')}
        />
        {formik.touched.site && formik.errors.site && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.site}</span>
            </div>
          </div>
        )}
        {errors?.site && <span className="text-danger">{errors?.site}</span>}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email (Notifikasi akan dikirim ke email ini) <span className="text-danger">*</span></label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {errors?.email && <span className="text-danger">{errors?.email}</span>}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Kompetensi Bidang Usaha <span className="text-danger">*</span></label>
        <div>
          <Select
            options={kompetensi}
            isLoading={loadingKompetensi}
            isClearable
            name={"kota"}
            onChange={handleChangeKompetensi}
          />
        </div>
        {formik.touched.base_kompetensi_bidang_usaha_id && formik.errors.base_kompetensi_bidang_usaha_id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.base_kompetensi_bidang_usaha_id}</span>
            </div>
          </div>
        )}
        {errors?.base_kompetensi_bidang_usaha_id && <span className="text-danger">{errors?.base_kompetensi_bidang_usaha_id}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username <span className="text-danger">*</span></label>
        <input
          placeholder='Username'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('userid')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.userid && formik.errors.userid,
            },
            {
              'is-valid': formik.touched.userid && !formik.errors.userid,
            }
          )}
        />
        {formik.touched.userid && formik.errors.userid && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.userid}</span>
            </div>
          </div>
        )}
        {errors?.userid && <span className="text-danger">{errors?.userid}</span>}
      </div>

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password <span className="text-danger">*</span></label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {errors?.password && <span className="text-danger">{errors?.password}</span>}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password <span className="text-danger">*</span></label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
        {errors?.password_confirmation && <span className="text-danger">{errors?.password_confirmation}</span>}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* end::Form group */}

      {/* <div className='fv-row mb-10'>
        <div className="g-recaptcha" data-sitekey="6Ldw6JAiAAAAALFWYOtCj75MmLRD-jRgP12XfCAl"></div>
        <ReCAPTCHA
          // ref={recaptchaRef}
          // size="invisible"
          sitekey={'6Ldw6JAiAAAAALFWYOtCj75MmLRD-jRgP12XfCAl'}
          {...formik.getFieldProps('recaptcha')}
          onChange={handleChangeCaptcha}
        />
        {formik.touched.recaptcha && formik.errors.recaptcha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.recaptcha}</span>
            </div>
          </div>
        )}
        {errors['g-recaptcha-response'] !== undefined && <span className="text-danger">{errors['g-recaptcha-response']}</span>}
      </div> */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid
            // || !formik.values.acceptTerms
          }
        >
          <span className='indicator-label'>Submit {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} </span>
        </button>
        <div className="m-t-20">
          {"Kembali ke Informasi"} <button type="button" className='btn btn-link p-0' onClick={() => props.setTabOpen('main')}>{"di sini"}</button>
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}
