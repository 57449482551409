/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState,
  // useRef
} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {registerRekrutmen} from '../redux/AuthCRUD'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import ReCAPTCHA from "react-google-recaptcha"
// import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const company_id = process.env.REACT_APP_BASE_COMPANY_ID || '1'
const app_type = process.env.REACT_APP_BASE_APPLICATION_TYPE_RECRUITMENT || '2'
const initialValues = {
  userid: '',
  company_id: company_id, //hardcode
  nipp: '',
  application_type: app_type,
  name: '',
  telp: '',
  nomor_ktp: '',
  email: '',
  password: '',
  password_confirmation: '',
  acceptTerms: false,
  // recaptcha: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  nomor_ktp: Yup.string()
    .min(16, 'Minimum 16 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Nomor KTP is required'),
  userid: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  telp: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 14 symbols')
    .required('Telepon is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  // recaptcha: Yup.string().required('Chaptcha is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function RegistrationRekrutmen(props:any) {
  // const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false)
  const [statusApi, setStatusApi] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const [errors, setErrors] = useState<any>({})

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      // const token = recaptchaRef.current?.getValue();
      // recaptchaRef.current?.reset();
      setErrors([])
      setLoading(true)
      setStatusApi(false)
      setTimeout(() => {
        registerRekrutmen(values.userid, values.company_id, values.nipp, values.application_type,
          values.name, values.telp, values.email, values.password, values.password_confirmation, values.nomor_ktp
          // , values.recaptcha
          )
          .then(() => {
            setLoading(false)
            
            setStatusApi(true)
            setStatusMessage("Register Sukses")
            setStatusColor("success")
            // toast.success("Registrasi Sukses !", {position: toast.POSITION.TOP_RIGHT});
            setTimeout(
              () => {
                props.setTabOpen('login')
              },
              2000
            )
          })
          .catch((err) => {
            console.log(err.data.message)
            setLoading(false)
            setSubmitting(false)
            setStatus(err.data.message)
            
            setStatusApi(true)
            // setStatusMessage("Register Gagal")
            setStatusMessage(err.data.message)
            setErrors(JSON.parse(err.data.message))
            setStatusColor('danger')
          })
      }, 1000)
    },
  })

  // const handleChange = (value:any) => {
  //   // console.log("Captcha value:", value);
  //   formik.setFieldValue('recaptcha', value)
  // };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Buat Akun Baru</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Sudah punya akun ?{' '}
          <button type="button" className='btn btn-link p-0 link-primary fw-bolder' onClick={() => props.setTabOpen('login')}>Login</button>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {statusApi && (
        <div className={'mb-lg-4 alert alert-'+ statusColor}>
          <div className='alert-text font-weight-bold'>{statusMessage}</div>
        </div>
      )}


      {/* begin::Form group Firstname */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Nama <span className="text-danger">*</span></label>
        <input
          placeholder='Nama'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
        {errors?.name && <span className="text-danger">{errors?.name}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Nomor KTP <span className="text-danger">*</span></label>
        <input
          placeholder='Nomor KTP'
          type="number"
          onWheel={e => e.currentTarget.blur()}
          autoComplete='off'
          {...formik.getFieldProps('nomor_ktp')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.nomor_ktp && formik.errors.nomor_ktp,
            },
            {
              'is-valid': formik.touched.nomor_ktp && !formik.errors.nomor_ktp,
            }
          )}
        />
        {formik.touched.nomor_ktp && formik.errors.nomor_ktp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.nomor_ktp}</span>
            </div>
          </div>
        )}
        {errors?.nomor_ktp && <span className="text-danger">{errors?.nomor_ktp}</span>}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Telepon <span className="text-danger">*</span></label>
        <input
          placeholder='Telepon'
          type="number"
          onWheel={e => e.currentTarget.blur()}
          autoComplete='off'
          {...formik.getFieldProps('telp')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.telp && formik.errors.telp,
            },
            {
              'is-valid': formik.touched.telp && !formik.errors.telp,
            }
          )}
        />
        {formik.touched.telp && formik.errors.telp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.telp}</span>
            </div>
          </div>
        )}
        {errors?.telp && <span className="text-danger">{errors?.telp}</span>}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email <span className="text-danger">*</span></label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {errors?.email && <span className="text-danger">{errors?.email}</span>}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username <span className="text-danger">*</span></label>
        <input
          placeholder='Username'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('userid')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.userid && formik.errors.userid,
            },
            {
              'is-valid': formik.touched.userid && !formik.errors.userid,
            }
          )}
        />
        {formik.touched.userid && formik.errors.userid && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.userid}</span>
            </div>
          </div>
        )}
        {errors?.userid && <span className="text-danger">{errors?.userid}</span>}
      </div>

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password <span className="text-danger">*</span></label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {errors?.password && <span className="text-danger">{errors?.password}</span>}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password <span className="text-danger">*</span></label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
        {errors?.password_confirmation && <span className="text-danger">{errors?.password_confirmation}</span>}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* end::Form group */}

      {/* <div className='fv-row mb-10'>
        <div className="g-recaptcha" data-sitekey="6Ldw6JAiAAAAALFWYOtCj75MmLRD-jRgP12XfCAl"></div>
        <ReCAPTCHA
          // ref={recaptchaRef}
          // size="invisible"
          sitekey={'6Ldw6JAiAAAAALFWYOtCj75MmLRD-jRgP12XfCAl'}
          {...formik.getFieldProps('recaptcha')}
          onChange={handleChange}
        />
        {formik.touched.recaptcha && formik.errors.recaptcha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.recaptcha}</span>
            </div>
          </div>
        )}
      </div> */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid
            // || !formik.values.acceptTerms
          }
        >
          <span className='indicator-label'>Submit {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>} </span>
        </button>
        <div className="m-t-20">
          {"Kembali ke Informasi"} <button type="button" className='btn btn-link p-0' onClick={() => props.setTabOpen('main')}>{"di sini"}</button>
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}
