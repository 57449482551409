import axios from 'axios'
// import * as usersType from '../constants/UserTypes';
// import {useDispatch} from 'react-redux'

// export const URL = `${process.env.REACT_APP_API_URL}user`
export const URL = `user`

export function FetchUserTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchAllUser(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-all-employees', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchUsers(params: any) {
    // return axios.get(URL+ '/show', {params: params})
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show', {params: params})
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}

export const CreateUsers = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/register', payload)
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}

export const UpdateUsers = (id: number, payload: any) => {
    // const dispatch = useDispatch()
    // return axios.post(URL+ '/update/' + id, payload)
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update/' + id, payload)
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}

export const DeleteUsers = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete/' + id, payload)
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}

export const UpdatePasswords = (payload: any) => {
    // const dispatch = useDispatch()
    // return axios.post(URL+ '/update/' + id, payload)
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update-password', payload)
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}

export const RegisterCentra = (payload: any) => {
    // const dispatch = useDispatch()
    // return axios.post(URL+ '/update/' + id, payload)
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/register-akun-centra', payload)
            .then(response => {
                resolve(response);
                // {data: response.data}
            })
            .catch(error => {
                reject(error.response);
                // dispatch({ type: usersType.FAIL_FETCH_USERS, response: error.response })
            });
    })
}
