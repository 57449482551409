import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// import en from '../translations/en.json';
import id from '../translations/id.json';
import button_id from '../translations/components/button_id.json';
import materi_penilaian_id from '../translations/master/materi_penilaian_id.json';
import bank_id from '../translations/master/bank_id.json';
import perusahaan_id from '../translations/master/perusahaan_id.json';
import cabang_id from '../translations/master/cabang_id.json';
import regional_id from '../translations/master/regional_id.json';
import hubungan_keluarga_id from '../translations/master/hubungan_keluarga_id.json';
import periode_rekrutmen_id from '../translations/master/periode_rekrutmen_id.json';
import jenis_persyaratan_id from '../translations/master/jenis_persyaratan_id.json';
import sim_id from '../translations/master/sim_id.json';
import media_sosial_id from '../translations/master/media_sosial_id.json';
import user_id from '../translations/master/user_id.json';
import tahapan_rekrutmen_id from '../translations/master/tahapan_rekrutmen_id.json';
import template_tahapan_penilaian_id from '../translations/master/template_tahapan_penilaian_id.json';
import jenis_pendidikan_id from '../translations/master/pendidikan/jenis_pendidikan_id.json';
import strata_pendidikan_id from '../translations/master/pendidikan/strata_pendidikan_id.json';
import lembaga_pendidikan_id from '../translations/master/pendidikan/lembaga_pendidikan_id.json';
import akreditasi_pendidikan_id from '../translations/master/pendidikan/akreditasi_pendidikan_id.json';
import jurusan_id from '../translations/master/pendidikan/jurusan_id.json';
import strata_jurusan_pendidikan_id from '../translations/master/pendidikan/strata_jurusan_pendidikan_id.json';
import strata_jurusan_institusi_id from '../translations/master/pendidikan/strata_jurusan_institusi_id.json';
import jurusan_pendidikan_id from '../translations/master/pendidikan/jurusan_pendidikan_id.json';
import banpt_id from '../translations/master/pendidikan/banpt_id.json';
import lowongan_id from '../translations/lowongan/lowongan_id.json';
import pelamar_id from '../translations/lowongan/pelamar_id.json';
import penilaian_id from '../translations/lowongan/penilaian_id.json';
import summary_hasil_penilaian_id from '../translations/reporting/summary_hasil_penilaian_id.json';
import peminatan_pelamar_id from '../translations/reporting/peminatan_pelamar_id.json';
import afiliasi_id from '../translations/master/afiliasi_id.json';
import workflow_id from '../translations/master/workflow_id.json';
import jenis_rekanan_id from '../translations/master/jenis_rekanan_id.json';
import kompetensi_bidang_usaha_id from '../translations/master/kompetensi_bidang_usaha_id.json';
import kompetensi_sub_bidang_usaha_id from '../translations/master/kompetensi_sub_bidang_usaha_id.json';
import vendor_id from '../translations/pengadaan/vendor_id.json';
import verifikasi_vendor_id from '../translations/pengadaan/verifikasi_vendor_id.json';
import profesi_id from '../translations/master/profesi_id.json';
import jenis_kompetensi_id from '../translations/master/jenis_kompetensi_id.json';
import klasifikasi_bidang_usaha_id from '../translations/master/klasifikasi_bidang_usaha_id.json';
import sub_klasifikasi_bidang_usaha_id from '../translations/master/sub_klasifikasi_bidang_usaha_id.json';
import klasifikasi_vendor_id from '../translations/master/klasifikasi_vendor_id.json';
import item_persyaratan_teknis_id from '../translations/master/item_persyaratan_teknis_id.json';
import unit_id from '../translations/master/unit_id.json';
import tipe_unit_id from '../translations/master/tipe_unit_id.json';

// KEUANGAN
import profit_center_id from '../translations/keuangan/master/profit_center_id.json';
import cost_center_id from '../translations/keuangan/master/cost_center_id.json';
import coa_id from '../translations/keuangan/master/coa_id.json';
import fund_center_id from '../translations/keuangan/master/fund_center_id.json';
import pajak_id from '../translations/keuangan/master/pajak_id.json';
import periode_id from '../translations/keuangan/master/periode_id.json';
import bahv_asuransi_id from '../translations/keuangan/transaksi/bahv_asuransi_id.json';
import bast_asuransi_id from '../translations/keuangan/transaksi/bast_asuransi_id.json';
import jurnal_keuangan_id from '../translations/keuangan/transaksi/jurnal_keuangan_id.json';
import surat_tagihan_id from '../translations/keuangan/transaksi/surat_tagihan_id.json';
import permintaan_pembayaran_id from '../translations/keuangan/transaksi/permintaan_pembayaran_id.json';
import pembayaran_id from '../translations/keuangan/transaksi/pembayaran_id.json';
import pelunasan_tagihan_id from '../translations/keuangan/transaksi/pelunasan_tagihan_id.json';
import rekon_bank_id from '../translations/keuangan/transaksi/rekon_bank_id.json';
import kas_kecil_id from '../translations/keuangan/transaksi/kas_kecil_id.json';
import clearing_dokumen_phc_id from '../translations/keuangan/transaksi/clearing_dokumen_phc_id.json';

// INVENTORY
import kategori_material_id from '../translations/inventory/master/kategori_material_id.json';
import sub_kategori_material_id from '../translations/inventory/master/sub_kategori_material_id.json';
import klasifikasi_material_id from '../translations/inventory/master/klasifikasi_material_id.json';
import satuan_material_id from '../translations/inventory/master/satuan_material_id.json';
import warehouse_id from '../translations/inventory/master/warehouse_id.json';
import storage_id from '../translations/inventory/master/storage_id.json';
import storage_rack_id from '../translations/inventory/master/storage_rack_id.json';
import manufacture_id from '../translations/inventory/master/manufacture_id.json';
import barang_id from '../translations/inventory/master/barang_id.json';
import bahan_id from '../translations/inventory/master/bahan_id.json';
import terapi_id from '../translations/inventory/master/terapi_id.json';
import sediaan_material_id from '../translations/inventory/master/sediaan_material_id.json';
import kandungan_bahan_id from '../translations/inventory/master/kandungan_bahan_id.json';
import kandungan_obat_id from '../translations/inventory/master/kandungan_obat_id.json';
import jasa_id from '../translations/inventory/master/jasa_id.json';
import jenis_mutasi_barang_id from '../translations/inventory/master/jenis_mutasi_barang_id.json';
import jenis_dokumen_vendor_id from '../translations/pengadaan/jenis_dokumen_vendor_id.json';
import permintaan_barang_id from '../translations/inventory/transaksi/permintaan_barang_id.json';
import permintaan_pembelian_id from '../translations/inventory/transaksi/permintaan_pembelian_id.json';
import pemesanan_id from '../translations/inventory/transaksi/pemesanan_id.json';
import penerimaan_barang_id from '../translations/inventory/transaksi/penerimaan_barang_id.json';
import pengeluaran_barang_id from '../translations/inventory/transaksi/pengeluaran_barang_id.json';
import stock_opname_id from '../translations/inventory/transaksi/stock_opname_id.json';
import mutasi_unit_id from '../translations/inventory/transaksi/mutasi_unit_id.json';
import kartu_stok_phc_id from '../translations/inventory/transaksi/kartu_stok_phc_id.json';

// MANAJEMEN ASSET
import kategori_id from '../translations/manajemen_asset/master/kategori_id.json';
import klasifikasi_id from '../translations/manajemen_asset/master/klasifikasi_id.json';
import tipe_id from '../translations/manajemen_asset/master/tipe_id.json';
import merk_id from '../translations/manajemen_asset/master/merk_id.json';
import tipe_merk_id from '../translations/manajemen_asset/master/tipe_merk_id.json';
import skala_resiko_id from '../translations/manajemen_asset/master/skala_resiko_id.json';
import skala_pemeliharaan_id from '../translations/manajemen_asset/master/skala_pemeliharaan_id.json';
import skala_insiden_id from '../translations/manajemen_asset/master/skala_insiden_id.json';
import skala_fungsi_id from '../translations/manajemen_asset/master/skala_fungsi_id.json';
import frekuensi_inspeksi_id from '../translations/manajemen_asset/master/frekuensi_inspeksi_id.json';
import grade_em_id from '../translations/manajemen_asset/master/grade_em_id.json';
import fungsi_perangkat_id from '../translations/manajemen_asset/master/fungsi_perangkat_id.json';
import check_list_pemeliharaan_id from '../translations/manajemen_asset/master/check_list_pemeliharaan_id.json';
import tipe_service_id from '../translations/manajemen_asset/master/tipe_service_id.json';
import status_service_id from '../translations/manajemen_asset/master/status_service_id.json';
import perangkat_id from '../translations/manajemen_asset/master/perangkat_id.json';
import inventaris_id from '../translations/manajemen_asset/master/inventaris_id.json';

import sosialisasi_perangkat_id from '../translations/manajemen_asset/pemeliharaan/sosialisasi_perangkat_id.json';
import inspeksi_perangkat_id from '../translations/manajemen_asset/pemeliharaan/inspeksi_perangkat_id.json';
import penetapan_jadwal_id from '../translations/manajemen_asset/pemeliharaan/penetapan_jadwal_id.json';
import pemeliharaan_id from '../translations/manajemen_asset/pemeliharaan/pemeliharaan_id.json';
import service_id from '../translations/manajemen_asset/pemeliharaan/service_id.json';
import jadwal_kalibrasi_id from '../translations/manajemen_asset/kalibrasi/jadwal_kalibrasi_id.json';
import form_kalibrasi_id from '../translations/manajemen_asset/kalibrasi/form_kalibrasi_id.json';

import distribusi_id from '../translations/manajemen_asset/pengaturan/distribusi_id.json';
import relokasi_id from '../translations/manajemen_asset/pengaturan/relokasi_id.json';

export const defaultNS = 'common'
export const resources = {
  en: {
    common: id,
    button: button_id,
    afiliasi: afiliasi_id,
    jenis_rekanan: jenis_rekanan_id,
    kompetensi_bidang_usaha : kompetensi_bidang_usaha_id,
    kompetensi_sub_bidang_usaha : kompetensi_sub_bidang_usaha_id,
    materi_penilaian: materi_penilaian_id,
    bank: bank_id,
    perusahaan: perusahaan_id,
    cabang: cabang_id,
    regional: regional_id,
    hubungan_keluarga: hubungan_keluarga_id,
    periode_rekrutmen: periode_rekrutmen_id,
    jenis_persyaratan: jenis_persyaratan_id,
    tahapan_rekrutmen: tahapan_rekrutmen_id,
    sim: sim_id,
    media_sosial: media_sosial_id,
    template_tahapan_penilaian: template_tahapan_penilaian_id,
    user: user_id,
    workflow: workflow_id,

    jenis_pendidikan: jenis_pendidikan_id,
    strata_pendidikan: strata_pendidikan_id,
    lembaga_pendidikan: lembaga_pendidikan_id,
    akreditasi_pendidikan: akreditasi_pendidikan_id,
    jurusan: jurusan_id,
    strata_jurusan_pendidikan: strata_jurusan_pendidikan_id,
    strata_jurusan_institusi: strata_jurusan_institusi_id,
    jurusan_pendidikan: jurusan_pendidikan_id,
    banpt: banpt_id,

    lowongan: lowongan_id,
    pelamar: pelamar_id,
    penilaian: penilaian_id,

    summary_hasil_penilaian: summary_hasil_penilaian_id,
    peminatan_pelamar: peminatan_pelamar_id,

    vendor: vendor_id,
    verifikasi_vendor: verifikasi_vendor_id,
    profesi: profesi_id,
    jenis_kompetensi: jenis_kompetensi_id,
    klasifikasi_bidang_usaha: klasifikasi_bidang_usaha_id,
    sub_klasifikasi_bidang_usaha: sub_klasifikasi_bidang_usaha_id,
    klasifikasi_vendor: klasifikasi_vendor_id,
    item_persyaratan_teknis: item_persyaratan_teknis_id,

    profit_center: profit_center_id,
    cost_center: cost_center_id,
    coa: coa_id,
    fund_center: fund_center_id,
    pajak: pajak_id,
    periode: periode_id,
    bahv_asuransi: bahv_asuransi_id,
    bast_asuransi: bast_asuransi_id,
    jurnal_keuangan: jurnal_keuangan_id,
    surat_tagihan: surat_tagihan_id,
    pelunasan_tagihan: pelunasan_tagihan_id,
    permintaan_pembayaran: permintaan_pembayaran_id,
    pembayaran: pembayaran_id,
    rekon_bank: rekon_bank_id,
    kas_kecil: kas_kecil_id,
    clearing_dokumen_phc: clearing_dokumen_phc_id,

    kategori_material: kategori_material_id,
    sub_kategori_material: sub_kategori_material_id,
    klasifikasi_material: klasifikasi_material_id,
    satuan_material: satuan_material_id,
    warehouse: warehouse_id,
    storage: storage_id,
    storage_rack: storage_rack_id,
    manufacture: manufacture_id,
    barang: barang_id,
    bahan: bahan_id,
    terapi: terapi_id,
    sediaan_material: sediaan_material_id,
    kandungan_bahan: kandungan_bahan_id,
    kandungan_obat: kandungan_obat_id,
    jasa: jasa_id,
    jenis_mutasi_barang: jenis_mutasi_barang_id,

    unit: unit_id,
    tipe_unit: tipe_unit_id,
    jenis_dokumen_vendor: jenis_dokumen_vendor_id,

    permintaan_barang: permintaan_barang_id,
    permintaan_pembelian: permintaan_pembelian_id,
    pemesanan: pemesanan_id,
    penerimaan_barang: penerimaan_barang_id,
    pengeluaran_barang: pengeluaran_barang_id,
    stock_opname: stock_opname_id,
    mutasi_unit: mutasi_unit_id,
    kartu_stok_phc: kartu_stok_phc_id,

    kategori_asset: kategori_id,
    klasifikasi_asset: klasifikasi_id,
    tipe_asset: tipe_id,
    merk_asset: merk_id,
    tipe_merk_asset: tipe_merk_id,
    skala_resiko: skala_resiko_id,
    skala_pemeliharaan: skala_pemeliharaan_id,
    skala_insiden: skala_insiden_id,
    skala_fungsi: skala_fungsi_id,
    frekuensi_inspeksi: frekuensi_inspeksi_id,
    grade_em: grade_em_id,
    fungsi_perangkat: fungsi_perangkat_id,
    check_list_pemeliharaan: check_list_pemeliharaan_id,
    tipe_service: tipe_service_id,
    status_service: status_service_id,
    perangkat: perangkat_id,
    inventaris: inventaris_id,
    sosialisasi_perangkat: sosialisasi_perangkat_id,
    inspeksi_perangkat: inspeksi_perangkat_id,
    penetapan_jadwal: penetapan_jadwal_id,
    pemeliharaan: pemeliharaan_id,
    service: service_id,
    jadwal_kalibrasi: jadwal_kalibrasi_id,
    form_kalibrasi: form_kalibrasi_id,
    relokasi: relokasi_id,
    distribusi: distribusi_id,
  },
  id: {
    common: id,
    button: button_id,
    afiliasi: afiliasi_id,
    jenis_rekanan: jenis_rekanan_id,
    kompetensi_bidang_usaha : kompetensi_bidang_usaha_id,
    kompetensi_sub_bidang_usaha : kompetensi_sub_bidang_usaha_id,
    materi_penilaian: materi_penilaian_id,
    bank: bank_id,
    perusahaan: perusahaan_id,
    cabang: cabang_id,
    regional: regional_id,
    hubungan_keluarga: hubungan_keluarga_id,
    periode_rekrutmen: periode_rekrutmen_id,
    jenis_persyaratan: jenis_persyaratan_id,
    tahapan_rekrutmen: tahapan_rekrutmen_id,
    sim: sim_id,
    media_sosial: media_sosial_id,
    template_tahapan_penilaian: template_tahapan_penilaian_id,
    user: user_id,
    workflow: workflow_id,

    jenis_pendidikan: jenis_pendidikan_id,
    strata_pendidikan: strata_pendidikan_id,
    lembaga_pendidikan: lembaga_pendidikan_id,
    akreditasi_pendidikan: akreditasi_pendidikan_id,
    jurusan: jurusan_id,
    strata_jurusan_pendidikan: strata_jurusan_pendidikan_id,
    strata_jurusan_institusi: strata_jurusan_institusi_id,
    jurusan_pendidikan: jurusan_pendidikan_id,
    banpt: banpt_id,

    lowongan: lowongan_id,
    pelamar: pelamar_id,
    penilaian: penilaian_id,

    summary_hasil_penilaian: summary_hasil_penilaian_id,
    peminatan_pelamar: peminatan_pelamar_id,

    vendor: vendor_id,
    verifikasi_vendor: verifikasi_vendor_id,
    profesi: profesi_id,
    jenis_kompetensi: jenis_kompetensi_id,
    klasifikasi_bidang_usaha: klasifikasi_bidang_usaha_id,
    sub_klasifikasi_bidang_usaha: sub_klasifikasi_bidang_usaha_id,
    klasifikasi_vendor: klasifikasi_vendor_id,
    item_persyaratan_teknis: item_persyaratan_teknis_id,

    profit_center: profit_center_id,
    cost_center: cost_center_id,
    coa: coa_id,
    fund_center: fund_center_id,
    pajak: pajak_id,
    periode: periode_id,
    bahv_asuransi: bahv_asuransi_id,
    bast_asuransi: bast_asuransi_id,
    jurnal_keuangan: jurnal_keuangan_id,
    pelunasan_tagihan: pelunasan_tagihan_id,
    surat_tagihan: surat_tagihan_id,
    permintaan_pembayaran: permintaan_pembayaran_id,
    pembayaran: pembayaran_id,
    rekon_bank: rekon_bank_id,
    kas_kecil: kas_kecil_id,
    clearing_dokumen_phc: clearing_dokumen_phc_id,

    kategori_material: kategori_material_id,
    sub_kategori_material: sub_kategori_material_id,
    klasifikasi_material: klasifikasi_material_id,
    satuan_material: satuan_material_id,
    warehouse: warehouse_id,
    storage: storage_id,
    storage_rack: storage_rack_id,
    manufacture: manufacture_id,
    barang: barang_id,
    bahan: bahan_id,
    terapi: terapi_id,
    sediaan_material: sediaan_material_id,
    kandungan_bahan: kandungan_bahan_id,
    kandungan_obat: kandungan_obat_id,
    jasa: jasa_id,
    jenis_mutasi_barang: jenis_mutasi_barang_id,

    unit: unit_id,
    tipe_unit: tipe_unit_id,
    jenis_dokumen_vendor: jenis_dokumen_vendor_id,

    permintaan_barang: permintaan_barang_id,
    permintaan_pembelian: permintaan_pembelian_id,
    pemesanan: pemesanan_id,
    penerimaan_barang: penerimaan_barang_id,
    pengeluaran_barang: pengeluaran_barang_id,
    stock_opname: stock_opname_id,
    mutasi_unit: mutasi_unit_id,
    kartu_stok_phc: kartu_stok_phc_id,

    kategori_asset: kategori_id,
    klasifikasi_asset: klasifikasi_id,
    tipe_asset: tipe_id,
    merk_asset: merk_id,
    tipe_merk_asset: tipe_merk_id,
    skala_resiko: skala_resiko_id,
    skala_pemeliharaan: skala_pemeliharaan_id,
    skala_insiden: skala_insiden_id,
    skala_fungsi: skala_fungsi_id,
    frekuensi_inspeksi: frekuensi_inspeksi_id,
    grade_em: grade_em_id,
    fungsi_perangkat: fungsi_perangkat_id,
    check_list_pemeliharaan: check_list_pemeliharaan_id,
    tipe_service: tipe_service_id,
    status_service: status_service_id,
    perangkat: perangkat_id,
    inventaris: inventaris_id,
    sosialisasi_perangkat: sosialisasi_perangkat_id,
    inspeksi_perangkat: inspeksi_perangkat_id,
    penetapan_jadwal: penetapan_jadwal_id,
    pemeliharaan: pemeliharaan_id,
    service: service_id,
    jadwal_kalibrasi: jadwal_kalibrasi_id,
    form_kalibrasi: form_kalibrasi_id,

    relokasi: relokasi_id,
    distribusi: distribusi_id,
  }
} as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
  lng: 'id',
  debug: false,
  // debug: true,
  resources: resources,
  ns: ["common"],
  defaultNS: "common",
  fallbackLng: "id",
  keySeparator: ".", // we use content as keys
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
