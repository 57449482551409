import React, {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
// import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import { App as AppCapacitor } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import CacheBuster from '../CacheBuster';

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      AppCapacitor.addListener("backButton", () => {
        if (window.location.pathname === "/" || window.location.pathname === "/beranda") {
          AppCapacitor.exitApp()
        }else{
          window.history.back()
        } 
      });
    }
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              {/* <I18nProvider>
              </I18nProvider> */}
              <LayoutProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </LayoutProvider>
            </BrowserRouter>
          </Suspense>
        )
      }}
    </CacheBuster>
  )
}

export {App}
