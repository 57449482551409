export function formattingDate(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  
  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;

  if (isNaN(d.getTime())) {
    [day, month, year] = e.split('-')
  }

  return [year, month, day].join('-');
}

export function formattingDateRevert(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;

  return [day, month, year].join('-');
}

export function formattingDateRevertString(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;

  switch (month) {
    case '01':
      month = "Januari"
      break;
    case '02':
      month = "Februari"
      break;
    case '03':
      month = "Maret"
      break;
    case '04':
      month = "April"
      break;
    case '05':
      month = "Mei"
      break;
    case '06':
      month = "Juni"
      break;
    case '07':
      month = "Juli"
      break;
    case '08':
      month = "Agustus"
      break;
    case '09':
      month = "September"
      break;
    case '10':
      month = "Oktober"
      break;
    case '11':
      month = "November"
      break;
    case '12':
      month = "Desember"
      break;
    default:
      break;
  }
  return [day, month, year].join(' ');
}

export function formattingDateTimeRevertString(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours()),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;

  switch (month) {
    case '01':
      month = "Januari"
      break;
    case '02':
      month = "Februari"
      break;
    case '03':
      month = "Maret"
      break;
    case '04':
      month = "April"
      break;
    case '05':
      month = "Mei"
      break;
    case '06':
      month = "Juni"
      break;
    case '07':
      month = "Juli"
      break;
    case '08':
      month = "Agustus"
      break;
    case '09':
      month = "September"
      break;
    case '10':
      month = "Oktober"
      break;
    case '11':
      month = "November"
      break;
    case '12':
      month = "Desember"
      break;
    default:
      break;
  }
  return [day, month, year].join(' ') + " " + [hour, minute].join(':');
}

export function formattingDateTime(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    second = '' + (d.getSeconds()),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours()),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;
  if (second.length < 2) 
    second = '0' + second;

  return [year, month, day].join('-') + " " + [hour, minute, second].join(':');
}

export function formattingDateTimeRevert(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    second = '' + (d.getSeconds()),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours()),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;
  if (second.length < 2) 
    second = '0' + second;
  
  const datetime = [day, month, year].join('-') + " " + [hour, minute, second].join(':')

  return datetime;
}

export function formattingTimeRevert(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    second = '' + (d.getSeconds()),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours())

  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;
  if (second.length < 2) 
    second = '0' + second;
  
  const time = [hour, minute, second].join(':')

  return time;
}

export function formattingDateTimeNoSecond(e: any) {
  if (e === undefined || e === null || e === "") return "";
  let d = new Date(e),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours()),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;

  return [year, month, day].join('-') + " " + [hour, minute].join(':');
}

export function formattingDateTimeNoSecondRevert(e: any) {
  if (e === undefined || e === null || e === "" || (e instanceof Date && !isFinite(+e))) return "";
  let d = new Date(e),
    minute = '' + (d.getMinutes()),
    hour = '' + (d.getHours()),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
  if (hour.length < 2) 
    hour = '0' + hour;
  if (minute.length < 2) 
    minute = '0' + minute;
  
  const datetime = [day, month, year].join('-') + " " + [hour, minute].join(':')

  return datetime;
}

export function monthName(e: any) {
  if (e === undefined || e === null || e === "") return "";
  let month = ""
  switch (Number(e)) {
    case 1:
      month = "Januari"
      break;
    case 2:
      month = "Februari"
      break;
    case 3:
      month = "Maret"
      break;
    case 4:
      month = "April"
      break;
    case 5:
      month = "Mei"
      break;
    case 6:
      month = "Juni"
      break;
    case 7:
      month = "Juli"
      break;
    case 8:
      month = "Agustus"
      break;
    case 9:
      month = "September"
      break;
    case 10:
      month = "Oktober"
      break;
    case 11:
      month = "November"
      break;
    case 12:
      month = "Desember"
      break;
    default:
      break;
  }
  return month;
}
