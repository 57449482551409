import axios from 'axios'

export const URL = `lowongan`

export function ShowDetailLowongan(id: any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${URL}/showbyid/`+id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLowonganAll(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${URL}/show-all`, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchDetailPelamar(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(`${URL}/show-pelamar`, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLowonganTable(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show', {params: params})
        axios.get(URL+ '/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLowongan(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show', {params: params})
        axios.get(URL+ '/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLowonganPublics(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show-noauth', {params: params})
        axios.get(URL+ '/show-noauth', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchActiveLowongan(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show-aktif', {params: params})
        axios.get(URL+ '/show-aktif', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchUserLowongan(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show-per-user', {params: params})
        axios.get(URL+ '/show-per-user', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateLowongan = (payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/store', payload)
        axios.post(URL+ '/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateLowongan = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/update/' + id, payload)
        axios.post(URL+ '/update/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const LockLowongan = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/lock/' + id, payload)
        axios.post(URL+ '/lock/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UnlockLowongan = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/unlock/' + id, payload)
        axios.post(URL+ '/unlock/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SyncLowongan = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/lock/' + id, payload)
        axios.post(URL+ '/sync/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteLowongan = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/delete/' + id, payload)
        axios.post(URL+ '/delete/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CopyLowongan = (payload: any) => {
    return new Promise((resolve, reject) => {
        // axios.post(URL+ 'lowongan/copy', payload)
        axios.post(URL+ '/copy', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchPenilaiPerUser(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(URL+ 'lowongan/show', {params: params})
        axios.get(URL+ '/show-per-user-penilai', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

