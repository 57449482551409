import { useMemo } from "react";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import { useTranslation } from 'react-i18next'
import { formattingDateRevert } from "../../../../_metronic/helpers/formattingDate";

export const Table = ( props:any ) => {
  const { t } = useTranslation();
  const data = useMemo(()=>props.data,[props.data])
  const columns = useMemo(() => [
    {
      accessorKey: 'regional.deskripsi',
      header: t`perusahaan:title`,
      // enableColumnFilter: true,
    },
    {
      accessorKey: 'si_hrd_profesi_name',
      header: t`lowongan:label.profesi`,
      // enableColumnFilter: true,
    },
    {
      header: t`label.aktif_dari`,
      maxSize: 2,
      Cell: ({ cell }) => (
        <span style={{display: "inline"}}>
          {formattingDateRevert(cell.row.original.aktif_dari)}
        </span>
      )
    },
    {
      header: t`label.aktif_sampai`,
      maxSize: 2,
      Cell: ({ cell }) => (
        <span style={{display: "inline"}}>
          {formattingDateRevert(cell.row.original.aktif_sampai)}
        </span>
      )
    },
    {
      header: t`label.status`,
      maxSize: 2,
      Cell: ({ cell }) => (
        <span style={{display: "inline"}}>
          {cell.row.original.locked === 1 ? "closed" : "open"}
        </span>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ] as MRT_ColumnDef<typeof data[0]>[],[]);

  return (
    <div className="">
      <div className="my-6">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnActions={false}
          initialState={{ showColumnFilters: false}}
          muiTableHeadCellProps={{
        align: 'center',
        sx: {
          fontSize: '14px',
          '& hr': {
            height: '18px', borderWidth: '2px'
          }, padding: '6px', border: '1px solid rgba(81, 81, 81, .1)'
        }
      }}
          muiTableBodyCellProps={{ sx: { fontSize: '14px', padding: '6px', border: '1px solid rgba(81, 81, 81, .1)'} }}
          muiBottomToolbarProps={{
            sx:{zIndex: 0},
          }}
          enableTopToolbar={false}
          // enableBottomToolbar={false}
          />
      </div>
    </div>
  );
}
