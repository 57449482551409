export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      let url:string  = '';
      if (document.location.host.indexOf('localhost')>=0){
        url = `${process.env.REACT_APP_API_URL_DEV}`
      }else if (document.location.host.indexOf('192.168')>=0){
        url = `${process.env.REACT_APP_API_URL_LOCAL_PROD}`
      }else if (document.location.protocol.indexOf('https')>=0){
        url = `${process.env.REACT_APP_API_URL}`
      }else{
        url = `${process.env.REACT_APP_API_URL_LOCAL_PUBLIC_PROD}`
      }

      config.baseURL  = url

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
