import styled from "styled-components";

export const Div = styled("div")`
  width: 50%;
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;

export const BgDiv = styled("div")`
  background-size: cover;
  height: 100%;
  overflow: hidden;
`;
