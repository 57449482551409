/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useLayout} from '../../../../_metronic/layout/core'
// import clsx from 'clsx'
import { useHistory } from "react-router-dom";
import {useState, useEffect} from 'react'

export const ToolbarReplaced = (props:any) => {
  // const {classes} = useLayout()
  // const path_open = window.location.pathname
  const urltab = window.location.pathname.split('/')
  const [tab, setTab] = useState(urltab[urltab.length - 1]);
  const history = useHistory()
  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    history.push(tab);
  }, [tab, history])


  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          // console.log('forward button');
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
          // console.log('back button');
        }
        const arr_url = window.location.pathname.split('/')
        setTab(arr_url[arr_url.length - 1])
      }
    });
  }, [locationKeys, history]);

  return (
    <div className="toolbar">
      <div className='d-flex overflow-auto bg-white'>
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap px-8">
        {/* <ul className="profile-header-tab nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder"> */}
          <li className="nav-item">
            <button type="button" className='btn btn-link p-0' onClick={(e) => setTab('dashboard')}>
              <span className={'d-flex nav-link text-active-primary me-6 fw-bolder fs-5 ' + (tab === 'dashboard' && 'active')}>Beranda</span>
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className='btn btn-link p-0' onClick={(e) => setTab('pelamar-data-diri')}>
              <span className={'d-flex nav-link text-active-primary me-6 fw-bolder fs-5 ' + (tab === 'pelamar-data-diri' && 'active')}>Data Diri Pelamar</span>
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className='btn btn-link p-0' onClick={(e) => setTab('pelamar-lowongan')}>
              <span className={'d-flex nav-link text-active-primary me-6 fw-bolder fs-5 ' + (tab === 'pelamar-lowongan' && 'active')}>Lowongan (Kirim Lamaran)</span>
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className='btn btn-link p-0' onClick={(e) => setTab('pelamar-history-lowongan')}>
              <span className={'d-flex nav-link text-active-primary me-6 fw-bolder fs-5 ' + (tab === 'pelamar-history-lowongan' && 'active')}>Riwayat Lowongan</span>
            </button>
          </li>
          {/* <li className="nav-item">
            <button type="button" className='btn btn-link py-0 my-0' onClick={(e) => setTab('pelamar-data-diri')}>
              <span className={'nav-link text-active-primary me-6 fw-bolder ' + (tab === 'pelamar-data-diri' && 'active')}>Data Diri Pelamar</span>
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className='btn btn-link py-0 my-0' onClick={(e) => setTab('pelamar-lowongan')}>
              <span className={'nav-link text-active-primary me-6 fw-bolder ' + (tab === 'pelamar-lowongan' && 'active')}>Lowongan (Kirim Lamaran)</span>
            </button>
          </li>
          <li className="nav-item">
            <button type="button" className='btn btn-link py-0 my-0' onClick={(e) => setTab('pelamar-history-lowongan')}>
              <span className={'nav-link text-active-primary me-6 fw-bolder ' + (tab === 'pelamar-history-lowongan' && 'active')}>Riwayat Lowongan</span>
            </button>
          </li> */}
        </ul>
      </div>
    </div>
  );
}