export const checkRole = (param:any) => {
    const default_role:any = {
      approve: false,
      assign: false,
      block: false,
      create: false,
      delete: false,
      duplicate: false,
      edit: false,
      export: false,
      index: false,
      lock: false,
      publish: false,
      send: false,
      store: false,
      submit: false,
      sync: false,
      unlock: false,
      unpublish: false,
      update: false,
      view: false,
      void: false,
    }
    param.forEach((element:any) => {
      if(default_role[element?.nama_aksi] === undefined){default_role[element?.nama_aksi] = true}
      default_role[element?.nama_aksi] = true
    });
    return default_role
}

export const checkUrlWithCompanyId = (arr:any) => {
  return Number.isNaN(Number(arr[arr.length - 2])) ? false : true
}

// export const checkAccessRoleUrl = (url:any, base_perusahaan_id:any) => {
//   const arr_url = url.split('/')
//   let base_url = ''
//   let arr_temp:any = []
//   let base_url_length = arr_url.length-3

//   const url_id = arr_url[arr_url.length - 1]
//   let url_access = arr_url[arr_url.length - 3]
//   let company_id = arr_url[arr_url.length - 2]
//   if (url.includes('create')) {
//     if (arr_url[arr_url.length - 1] === 'create') {
//       url_access = arr_url[arr_url.length - 1]
//       company_id = base_perusahaan_id

//       base_url_length = arr_url.length-1
//     }
//     else {
//       url_access = arr_url[arr_url.length - 2]
//       company_id = arr_url[arr_url.length - 1]
//       base_url_length = arr_url.length-2
//     }
//   }
//   else {
//     const url_with_company_id = checkUrlWithCompanyId(arr_url)
//     if (!url_with_company_id) {
//       url_access = arr_url[arr_url.length - 2]
//       company_id = base_perusahaan_id
//     } 
//   }

//   for (let i = 0; i < base_url_length; i++) {
//     arr_temp.push(arr_url[i])
//   }
//   base_url = arr_temp.join('/')

//   const obj = {
//     access_menu: url_access,
//     base_url: base_url,
//     company_id: company_id,
//     id: url_id,
//   }

//   return obj
// }

export const encryptData = (params:any) => {
  let CryptoJS = require("crypto-js");
  const encrypt = CryptoJS.AES.encrypt(JSON.stringify(params), process.env.REACT_APP_CRYPTO_SECRET_PASS).toString()
  const encodeURI = encodeURIComponent(encrypt)
  return encodeURI;
}

export const decryptData = (params:any) => {
  let CryptoJS = require("crypto-js");
  const decodeURI = decodeURIComponent(params)
  const decrypt = CryptoJS.AES.decrypt(decodeURI, process.env.REACT_APP_CRYPTO_SECRET_PASS).toString(CryptoJS.enc.Utf8)
  return JSON.parse(decrypt);
}

export const checkAccessRoleUrl = (url:any) => {
  const arr_url = url.split('/')

  return decryptData(arr_url[arr_url.length - 1])
}

