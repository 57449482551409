import axios from 'axios'

export const URL = `md-pengadaan`
export const URL_KLASIFIKASI_BIDANG_USAHA = `${URL}/bidang-usaha`
export const URL_KOMPETENSI_BIDANG_USAHA = `${URL}/kompetensi-bidang-usaha`
export const URL_KOMPETENSI_SUB_BIDANG_USAHA = `${URL}/kompetensi-sub-bidang-usaha`
export const URL_JENIS_TIPE_REKANAN = `${URL}/jenis-tipe-rekanan`

export function FetchJenisDokumen(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-dokumen/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisUrgensiDokumen(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-urgensi-dokumen/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisAkta(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-akta-vendor/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisSppkp(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-sppkp/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisPemegangSaham(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-pemegang-saham/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisTipeRekanan(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-tipe-rekanan/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchJenisLaporanKeuanganLain(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/jenis-laporan-keuangan-lain/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKualifikasiKonstruksi(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/kualifikasi-konstruksi/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKualifikasiSkalaUsaha(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/kualifikasi-skala-usaha/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

// export function FetchKlasifikasiBidangUsaha(params: any) {
//     return new Promise((resolve, reject) => {
//         axios.get(URL+ '/bidang-usaha/show-klasifikasi', {params: params})
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(error => {
//                 reject(error.response);
//             });
//     })
// }

// BIDANG USAHA
export function FetchKompetensiBidangUsahaTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KOMPETENSI_BIDANG_USAHA+ '/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKompetensiBidangUsaha(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KOMPETENSI_BIDANG_USAHA+ '/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKompetensiBidangUsahaNoAuth(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KOMPETENSI_BIDANG_USAHA+ '/show-noauth', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateKompetensiBidangUsaha = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_BIDANG_USAHA+ '/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKompetensiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_BIDANG_USAHA+ '/update/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKompetensiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_BIDANG_USAHA+ '/delete/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

// SUB BIDANG USAHA
// export function FetchSubKlasifikasiBidangUsaha(params: any, id: any) {
//     return new Promise((resolve, reject) => {
//         axios.get(URL+ '/bidang-usaha/show-sub-klasifikasi/' + id, {params: params})
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(error => {
//                 reject(error.response);
//             });
//     })
// }

export function FetchKompetensiSubBidangUsahaTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KOMPETENSI_SUB_BIDANG_USAHA+ '/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKompetensiSubBidangUsaha(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KOMPETENSI_SUB_BIDANG_USAHA+ '/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateKompetensiSubBidangUsaha = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_SUB_BIDANG_USAHA+ '/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKompetensiSubBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_SUB_BIDANG_USAHA+ '/update/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKompetensiSubBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KOMPETENSI_SUB_BIDANG_USAHA+ '/delete/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

// BIDANG USAHA
export function FetchKlasifikasiBidangUsahaTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KLASIFIKASI_BIDANG_USAHA+ '/show-klasifikasi-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKlasifikasiBidangUsaha(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KLASIFIKASI_BIDANG_USAHA+ '/show-klasifikasi', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateKlasifikasiBidangUsaha = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/store-klasifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKlasifikasiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/update-klasifikasi/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKlasifikasiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/delete-klasifikasi/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchSubKlasifikasiBidangUsahaTable(id: any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KLASIFIKASI_BIDANG_USAHA+ '/show-sub-klasifikasi-table/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchSubKlasifikasiBidangUsaha(id: any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_KLASIFIKASI_BIDANG_USAHA+ '/show-sub-klasifikasi/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateSubKlasifikasiBidangUsaha = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/store-sub-klasifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateSubKlasifikasiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/update-sub-klasifikasi/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteSubKlasifikasiBidangUsaha = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_KLASIFIKASI_BIDANG_USAHA+ '/delete-sub-klasifikasi/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKlasifikasiVendorTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_JENIS_TIPE_REKANAN+ '/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKlasifikasiVendor(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_JENIS_TIPE_REKANAN+ '/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateKlasifikasiVendor = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateKlasifikasiVendor = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteKlasifikasiVendor = (id: number, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}
