import {useState, useEffect} from "react"
import { SlideShow } from "./beranda/SlideShow";
import { Table } from "./beranda/Table";
import { FetchUserLowongan } from "../../modules/store/actions/recruitment/LowonganAction";
import { FetchSlideShow } from "../../modules/store/actions/LandingPageAction";

export const Info = (props:any) => {
  // const firstUpdate = useRef(true);
  const [data, setData] = useState([]);
  const [dataImg, setDataImg] = useState([]);
  
  useEffect(() => {
    let isMounted = true;
    const params = {
      company_id: 1,
    }
    FetchUserLowongan(params)
      .then((resp:any) => {
        const data = resp.data.data
        // toast.success("Fetch Data Sukses !", {position: toast.POSITION.TOP_RIGHT});
        if (isMounted) {
          setData(data)
        }
      })
      .catch(err => {
        console.log(err)
      })
    return () => { isMounted = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    let isMounted = true;
    const params = {
      company_id: 1,
      app_application_id: 2
    }
    FetchSlideShow(params)
      .then((resp:any) => {
        const data = resp.data.data
        // toast.success("Fetch Data Sukses !", {position: toast.POSITION.TOP_RIGHT});
        if (isMounted) {
          setDataImg(data)
        }
      })
      .catch(err => {
        console.log(err)
      })
    return () => { isMounted = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div className={"tab-pane fade show active"}>
      <div className="px-8 pt-8">
        <div className="row">
          <h1>Informasi Lowongan</h1>
          <div className="col-md-8">
            <Table data={data} />
          </div>
          <div className="col-md-4 my-6">
            <SlideShow data={dataImg} />
          </div>
        </div>
      </div>
    </div>
  );
}