// import {useState} from 'react'
// import { ToolbarReplaced } from './pelamar/ToolbarReplaced';
import { Info } from '../menu_pelamar/Info';
// import { Contact } from './pelamar/Contact';
// import { ProfileData } from './pelamar/ProfileData';
// import { Lowongan } from './pelamar/Lowongan';
// import { HistoryLowongan } from './pelamar/HistoryLowongan';
import { Div } from './styles';
// import i18next from "i18next";

// const {t} = i18next

const DashboardPelamarWrapper = (props:any) => {
  
  // const [tab, setTab] = useState('beranda');
  // const [prevTab, setPrevTab] = useState('beranda');
  // const showTab = (_e:any, param:any) => {
  //   setPrevTab(tab)
  //   setTab(param)
  // }

  return (
    <Div>
      {/* <ToolbarReplaced tab={tab} showTab={showTab} /> */}
      <div className="profile-content">
        <div className="tab-content p-0">
          <Info />
          {/* <Contact tab={tab} prevTab={prevTab} /> */}
          {/* <ProfileData tab={tab} prevTab={prevTab} />
          <Lowongan tab={tab} prevTab={prevTab} />
          <HistoryLowongan tab={tab} prevTab={prevTab} /> */}
        </div>
      </div>
    </Div>
  );
}

export {DashboardPelamarWrapper}
