/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import i18next from "i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { checkAccessRoleUrl } from '../AccessRole';
import { PostUrlExternal } from '../../modules/store/actions/ExternalAction';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const {t} = i18next

const DashboardExternalLink: FC = () => {
  // let isMounted = true;
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [title, setTitle] = useState("")
  const [msg, setMsg] = useState("")
  const [urlTarget, setUrlTarget] = useState("")
  const [seconds, setSeconds] = useState<number>(3)

  useEffect(() => {
    let isMounted = true;
    console.log(location.pathname)
    const data_param_url = checkAccessRoleUrl(location.pathname)
    console.log(data_param_url)
    const {api_url, api_method, api_params, path_target, title} = data_param_url
    let target_url = path_target
    setTitle(title)
    if (isMounted) {
      api_method === 'post' && PostUrlExternal(api_url, api_params)
        .then((resp:any) => {
          toast.error("Sukses!", {position: toast.POSITION.TOP_RIGHT})
          setMsg("Silahkan Melengkapi Dokumen Join Tender")
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          toast.error(err.data.message, {position: toast.POSITION.TOP_RIGHT})
          setLoading(false)
          // target_url = '/vendor/join'
        })
        .finally(() => {
          setLoading(false)
          setUrlTarget(target_url)
        })
    }
    return () => { isMounted = false }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      if (seconds !== 0) {
        let sec = seconds - 1
        setTimeout(() => {
          setSeconds(sec)
        }, 1000);
      }
      else {
        history.push(urlTarget)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, seconds])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{t`menu.dashboard`}</PageTitle>
      <Modal returnFocusAfterClose={true} isOpen={true}>
        <ModalBody>
          <div className='text-center'>
            <div id="icon-modal">
              <span style={{alignItems: 'center', justifyContent: 'center', border: '2px solid rgb(221, 107, 85)', borderRadius: '36px', height: '72px', width: '72px', display: 'inline-grid', left: '50%'}}>
                <span className="fa fa-exclamation" style={{fontSize: '32pt', color: 'rgb(221, 107, 85)'}}></span>
              </span>
            </div>
            <div id="text-modal" className='mt-4'>
              <span className='fs-4 fw-bolder'>{title}</span>
            </div>
            {loading ?
              <i className="fas fa-spinner fa-pulse"></i> :
              <div>
                <div className='py-2'>{msg}</div>
                <div id="warning-modal">
                  <span id="divCounter" className='fs-5'>{seconds}</span>
                </div>
                {/* <div id="divCounter">{seconds}</div> */}
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export {DashboardExternalLink}
