import axios from 'axios'
// import {AuthModel} from '../models/AuthModel'
// import {UserModel} from '../models/UserModel'

// const API_URL = process.env.REACT_APP_API_URL

// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}user/verify`
// export const LOGIN_URL = `${API_URL}user/login`
// export const LOGOUT_URL = `${API_URL}user/logout`
// export const REGISTER_URL = `${API_URL}user/register`
// export const REGISTER_NOAUTH_URL = `${API_URL}user/register-noauth`
// export const REGISTER_VENDOR_NOAUTH_URL = `${API_URL}user/register-vendor-noauth`
// export const REQUEST_PASSWORD_URL = `${API_URL}user/reset-password`
export const GET_USER_BY_ACCESSTOKEN_URL = `user/verify`
export const LOGIN_URL = `user/login`
export const LOGOUT_URL = `user/logout`
export const REGISTER_URL = `user/register`
export const REGISTER_NOAUTH_URL = `user/register-noauth`
export const REGISTER_VENDOR_NOAUTH_URL = `user/register-vendor-noauth`
export const REQUEST_PASSWORD_URL = `user/reset-password`
export const company_id = process.env.REACT_APP_BASE_COMPANY_ID

// Server should return AuthModel
export function login(userid: string, password: string, application_type: string) {
  // console.log(LOGIN_URL)
  // return axios.post(LOGIN_URL, {userid, password})
  return new Promise((resolve, reject) => {
    axios.post(LOGIN_URL, {userid, password, application_type, company_id})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      });
  })
}

export function logout() {
  const config = {
    headers:{
      Authorization: `Bearer ` + localStorage.token
    }
  }
  return axios.post(LOGOUT_URL, config)
}

// Server should return AuthModel
export function registerRekrutmen(userid: string, company_id: string, nipp: string, application_type: string,
  name: string, telp: string, email: string, password: string, password_confirmation: string, nomor_ktp: string
  // , recaptcha:string
) {
  const param = {
    userid, company_id, nipp, application_type, name, telp, email, password, password_confirmation, nomor_ktp
    // , "g-recaptcha-response": recaptcha
    , "g-recaptcha-response": ""
  }
  return new Promise((resolve, reject) => {
    axios.post(REGISTER_NOAUTH_URL, param)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      });
  })
}

export function registerPengadaan(userid: string, company_id: string, nipp: string, application_type: string, nama: string,
  deskripsi: string, telp_1: string, telp_2: string, base_propinsi_id: string, base_kota_id: string, alamat: string, kode_pos: string,
  nomor_fax: string, site: string, email: string, base_kompetensi_bidang_usaha_id: string, password: string, password_confirmation: string
  // , recaptcha:string
) {
  const param = {
    userid, company_id, nipp, application_type, nama, deskripsi, telp_1, telp_2, base_propinsi_id, base_kota_id,
    alamat, kode_pos, nomor_fax, site, email, base_kompetensi_bidang_usaha_id, password, password_confirmation
    // , "g-recaptcha-response": recaptcha
    , "g-recaptcha-response": ""
  }
  return new Promise((resolve, reject) => {
    axios.post(REGISTER_VENDOR_NOAUTH_URL, param)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      });
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, company_id: string) {
  const param = { email, company_id }
  return new Promise((resolve, reject) => {
    axios.post(REQUEST_PASSWORD_URL, param)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      });
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  // return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
  const param = {
    application_type: JSON.parse(localStorage.aplikasi)[0].app_application_id
  }

  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {params: param});
}
