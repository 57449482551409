import React, {useRef, useEffect,
  // useState
} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
// import {AsideMenuMain} from './AsideMenuMain'
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../assets/ts/components'
import { ClassMenu } from './ClassMenu'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  // const { modul } = JSON.parse(localStorage.role)[0]
  // const [menu, setMenu] = useState<any>([])
  // console.log(modul)
  // const firstUpdate = useRef(true);

  // useEffect(() => {
  //   if (firstUpdate) {
  //     let temp_arr:any = {}
  //     modul.forEach((element:any) => {
  //       const kode = element.nama_kategori
  //       if (temp_arr[kode] === undefined) {
  //         temp_arr[kode] = []
  //       }
  //       else {
  //         (temp_arr[kode]).push(element)
  //       }
  //     });
  //     let final_arr:any = []
  //     let i = 0
  //     console.log(temp_arr)
  //     Object.keys(temp_arr).forEach(function(key) {
  //       final_arr.push(temp_arr[key])
  //       final_arr[i].kategori = key
  //       i++
  //     });
  //     console.log(final_arr)
  //     setMenu(final_arr)
      
  //     // console.log(temp_arr)
  //     // Object.keys(temp_arr).forEach(function(key) {
  //     //   console.log(key, temp_arr[key]);
  //     // });
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        {/* <AsideMenuMain /> */}
        <ClassMenu />
      </div>
    </div>
  )
}

export {AsideMenu}
