import axios from 'axios'

// export const URL = `${process.env.REACT_APP_API_URL}regional-noauth`
export const URL = `regional-noauth`

export function FetchProvinsi(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-provinsi', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchKota(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-kota', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

