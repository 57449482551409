import React from "react";
import { AsideMenuMain } from "./AsideMenuMain";

interface Props {
  // setData:any,
  // setDataLowongan:any,
}

interface State {
  data:any,
  show_menu:any,
}

export class ClassMenu extends React.Component<Props, State> {
  _isMounted = false;
  constructor(props:any) {
    super(props);
    this.state = {
      data: [],
      show_menu: false,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchMenu()
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (_state,_callback)=>{
      return;
    };
  }

	fetchMenu = () => {
    const modul = JSON.parse(localStorage.modul)
    // console.log(modul)
    let temp_arr:any = {}

    function setUrl(url:string) {
      let arr_url = url.split('/')
      arr_url.splice(-1)
      // const final_url = arr_url.join('/')
      return arr_url.join('/')
    }

    if (modul === undefined) {
      this.setState({data: [], show_menu: true})
    }
    else {
      modul.sort((a:any,b:any) => a.urutan - b.urutan).forEach((element:any) => {
        const kode_parent = element.nama_parent_kategori
        element.url_front = element.kelas[0].url_front
        element.type = "child"
        const kode = element.nama_kategori
        if ( kode_parent === null) {
          if (temp_arr[kode] === undefined) {
            temp_arr[kode] = []
          }
          (temp_arr[kode]).push(element)
        }
        else {
          if (temp_arr[kode_parent] === undefined) {
            temp_arr[kode_parent] = {}
          }
          if (temp_arr[kode_parent][kode] === undefined) {
            temp_arr[kode_parent][kode] = []
          }
          (temp_arr[kode_parent][kode]).push(element)
        }
      });
      let arr_main:any = []
      let i = 0
      // console.log(temp_arr)
  
      Object.keys(temp_arr).forEach(function(key) {
        arr_main[i] = []
        arr_main[i].kategori = key
        if (!Array.isArray(temp_arr[key])) {
          let arr_sub:any = []
          let j = 0
          Object.keys(temp_arr[key]).forEach(function(key2) {
            arr_sub[j] = []
            arr_sub[j].kategori = key2
            arr_sub[j].data = temp_arr[key][key2]
            arr_sub[j].url_front = setUrl(temp_arr[key][key2][0].kelas[0].url_front)
            arr_sub[j].type = "parent"
            j++
          })
          arr_main[i].type = "parent"
          arr_main[i].url_front = setUrl(arr_sub[0].url_front)
          // arr_sub[j].url_front = setUrl(temp_arr[key][key2][0].kelas[0].url_front)
          arr_main[i].data = arr_sub
        }
        else {
          arr_main[i].type = "parent"
          arr_main[i].url_front = setUrl(temp_arr[key][0].kelas[0].url_front)
          arr_main[i].data = temp_arr[key]
        }
        // arr_main[i].data = temp_arr[key]
        i++
      });
      // console.log(arr_main)
      this.setState({data: arr_main, show_menu: true})
      // this.setState({data: temp_arr, show_menu: true})
    }
	}

	fetchMenu2 = () => {
    const role = JSON.parse(localStorage.role)
    let temp_arr:any = {}

    function setUrl(url:string) {
      let arr_url = url.split('/')
      arr_url.splice(-1)
      // const final_url = arr_url.join('/')
      return arr_url.join('/')
    }

    role.forEach((element:any) => {
      element.modul.sort((a:any,b:any) => a.urutan - b.urutan).forEach((element:any) => {
        const kode_parent = element.nama_parent_kategori
        element.url_front = element.kelas[0].url_front
        element.type = "child"
        const kode = element.nama_kategori
        if ( kode_parent === null) {
          if (temp_arr[kode] === undefined) {
            temp_arr[kode] = []
          }
          (temp_arr[kode]).push(element)
        }
        else {
          if (temp_arr[kode_parent] === undefined) {
            temp_arr[kode_parent] = {}
          }
          if (temp_arr[kode_parent][kode] === undefined) {
            temp_arr[kode_parent][kode] = []
          }
          (temp_arr[kode_parent][kode]).push(element)
        }
      });
    });

    let arr_main:any = []
    let i = 0
    // console.log(temp_arr)

    Object.keys(temp_arr).forEach(function(key) {
      arr_main[i] = []
      arr_main[i].kategori = key
      if (!Array.isArray(temp_arr[key])) {
        let arr_sub:any = []
        let j = 0
        Object.keys(temp_arr[key]).forEach(function(key2) {
          arr_sub[j] = []
          arr_sub[j].kategori = key2
          arr_sub[j].data = temp_arr[key][key2]
          arr_sub[j].url_front = setUrl(temp_arr[key][key2][0].kelas[0].url_front)
          arr_sub[j].type = "parent"
          j++
        })
        arr_main[i].type = "parent"
        arr_main[i].url_front = setUrl(arr_sub[0].url_front)
        // arr_sub[j].url_front = setUrl(temp_arr[key][key2][0].kelas[0].url_front)
        arr_main[i].data = arr_sub
      }
      else {
        arr_main[i].type = "parent"
        arr_main[i].url_front = setUrl(temp_arr[key][0].kelas[0].url_front)
        arr_main[i].data = temp_arr[key]
      }
      // arr_main[i].data = temp_arr[key]
      i++
    });
    // console.log(arr_main)
    this.setState({data: arr_main, show_menu: true})
	}

  render() {
    return (
      <div>
        {this.state.show_menu &&
          <AsideMenuMain menu={this.state.data} />
        }
      </div>
    )
  }
}