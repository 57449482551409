// import { useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'

export const Form = ( props:any ) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const onSubmit = handleSubmit((data) => submitForm(data));

  const closeModal = (e:any, toggleModal:any) => {
    e.preventDefault()
    props.setToggleModal(toggleModal)
  }

  const submitForm = (data:any) => {
    props.modalOpen ==='password' && props.handleChangePassword(data)
    props.modalOpen ==='register' && props.handleRegisterUserCentra(data)
  }

  return (
    <div className="mx-4 my-8">
			<form id="form" onSubmit={onSubmit}>
        {props.modalOpen ==='password' &&
          <div>
            <div className="form-group pb-4">
              <label>Password Lama<span className="text-danger">*</span></label>
              <input type='password'className="form-control"  placeholder='Password Lama' autoComplete='off' {...register("old_password", { required: true })} />
              {errors?.old_password && <span className="text-danger">* This field is required</span>}
              {/* <div className="col-md-3">
              </div> */}
            </div>
            <div className="form-group pb-4">
              <label>Password Baru<span className="text-danger">*</span></label>
              <input type='password'className="form-control"  placeholder='Password Baru' autoComplete='off' {...register("password", { required: true })} />
              {errors?.password && <span className="text-danger">* This field is required</span>}
              {/* <div className="col-md-3">
              </div> */}
            </div>
            <div className="form-group pb-4">
              <label>Konfirmasi Password Baru<span className="text-danger">*</span></label>
              <input type='password'className="form-control"  placeholder='Konfirmasi Password Baru' autoComplete='off'
                {...register("password_confirmation", { required: true, 
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return "Your passwords do no match";
                    }
                  }
                })} />
              {errors?.password_confirmation && <span className="text-danger">{errors.password_confirmation.message}</span>}
              {/* <div className="col-md-3">
              </div> */}
            </div>
          </div>
        }
        {props.modalOpen ==='register' &&
          <div>
            <div className="form-group pb-4">
              <label>UserID<span className="text-danger">*</span></label>
              <input className="form-control"  placeholder='UserID Centra' autoComplete='off' {...register("centra_userid", { required: true })} />
              {errors?.centra_userid && <span className="text-danger">* This field is required</span>}
              {/* <div className="col-md-3">
              </div> */}
            </div>
            <div className="form-group pb-4">
              <label>Password<span className="text-danger">*</span></label>
              <input type='password'className="form-control"  placeholder='Password Centra' autoComplete='off' {...register("centra_password", { required: true })} />
              {errors?.centra_password && <span className="text-danger">* This field is required</span>}
              {/* <div className="col-md-3">
              </div> */}
            </div>
            {/* <div className="form-group pb-4">
              <label>Konfirmasi Password<span className="text-danger">*</span></label>
              <input type='password'className="form-control"  placeholder='Konfirmasi Password' autoComplete='off'
                {...register("centra_password_confirmation", { required: true, 
                  validate: (val: string) => {
                    if (watch('centra_password') !== val) {
                      return "Your passwords do no match";
                    }
                  }
                })} />
              {errors?.centra_password_confirmation && <span className="text-danger">{errors.centra_password_confirmation.message}</span>}
            </div> */}
          </div>
        }
        <div className="form-group pt-2">
          {/* eslint-disable-next-line */}
          <button disabled={props.loading} className="btn btn-secondary me-2" type="button" onClick={(e) => closeModal(e, !props.toggleModal)}>
            {t`button:back.title`}
          </button>
          <button disabled={props.loading} className="btn btn-primary" type="submit">
            {props.loading && <i className="fas fa-spinner fa-pulse"></i>}
            {t`button:submit.title`}
          </button>
        </div>
			</form>
    </div>
  );
}
