import axios from 'axios'

export function FetchImgLandingPage(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(process.env.REACT_APP_API_URL + 'aplikasi/show')
        axios.get('aplikasi/show-image-landing', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchSlideShow(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(process.env.REACT_APP_API_URL + 'aplikasi/show-image-slide', {params: params})
        axios.get('aplikasi/show-image-slide', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchShowAplikasi(params: any) {
    return new Promise((resolve, reject) => {
        // axios.get(process.env.REACT_APP_API_URL + 'aplikasi/show', {params: params})
        axios.get('aplikasi/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function StoreSaran(params: any) {
    return new Promise((resolve, reject) => {
        // axios.post(process.env.REACT_APP_API_URL + 'aplikasi/store-contact', params)
        axios.post('aplikasi/store-contact', params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function ReplySaran(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.post(URL + 'contact-response/' + id, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchTenderUmum(params: any) {
    return new Promise((resolve, reject) => {
        axios.get('tender/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}