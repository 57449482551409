import { useEffect, useRef, useState } from "react";
import "./style_image_page.css";
import { FilePath } from "../../../../setup/axios/FilePath"; 
// import { toAbsoluteUrl } from "../../../../_metronic/helpers"

const delay = 5000;

export const SlideShow = ( props:any ) => {
  const img_rotating = props.data;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === img_rotating.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
    }
    return () => {
      resetTimeout();
      isMounted = false
    };
  }, [index, img_rotating.length]);

  return (
    <div className="slideshow">
      <div className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
        {img_rotating.map((o:any, index:any) => (
          <img
            key={index}
            alt="Logo"
            className="logo slide"
            // src={`${process.env.REACT_APP_BASE_URL}storage/${o.path}`}
            src={`${FilePath}storage/${o.path}`}
          />
        ))}
      </div>

      <div className="slideshowDots">
        {img_rotating.map((_:any, idx:any) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}