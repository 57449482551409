/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import 
// React,
{FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthPage, Logout} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import { lazy } from "react";

const Routes: FC = () => {
  const isAuthorized:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  
  const existingUrl = window.location.href
  const baseUrlSites = process.env.REACT_APP_SITES_URL || ""
  const subUrlAdmin = process.env.REACT_APP_ADMIN_SUB_URL || ""
  const baseUrlRecruitment = process.env.REACT_APP_RECRUITMENT_URL || ""
  const subUrlRecruitment = process.env.REACT_APP_RECRUITMENT_SUB_URL || ""
  const baseUrlProcurement = process.env.REACT_APP_PROCUREMENT_URL || ""
  const subUrlProcurement = process.env.REACT_APP_PROCUREMENT_SUB_URL || ""
  const officeUrl = process.env.REACT_APP_OFFICE_URL || ""
  const vercelUrl = process.env.REACT_APP_VERCEL_URL || ""

  const existingBaseUrlSites = existingUrl.replace(/https?:\/\//i, "").includes(baseUrlSites)
  const existingBaseUrlRecruitment = existingUrl.replace(/https?:\/\//i, "").includes(baseUrlRecruitment)
  const existingBaseUrlProcurement = existingUrl.replace(/https?:\/\//i, "").includes(baseUrlProcurement)
  const existingBaseUrlVercel = existingUrl.replace(/https?:\/\//i, "").includes(vercelUrl)
  const existingBaseUrlOffice = existingUrl.replace(/https?:\/\//i, "").includes(officeUrl)

  const basePath =
    existingBaseUrlSites ? '/' + subUrlAdmin  :
    existingBaseUrlRecruitment ? '/' + subUrlRecruitment :
    existingBaseUrlProcurement ? '/' + subUrlProcurement :
    existingBaseUrlVercel ? '/' + subUrlAdmin  :
    existingBaseUrlOffice ? '/' + subUrlAdmin  :
    '/beranda'
  const baseKey =
    existingBaseUrlSites ? subUrlAdmin :
    existingBaseUrlRecruitment ? subUrlRecruitment :
    existingBaseUrlProcurement ? subUrlProcurement :
    existingBaseUrlVercel ? subUrlAdmin  :
    existingBaseUrlOffice ? subUrlAdmin  :
    'beranda'

  return (
    <Switch>
      { !isAuthorized ?
        <Route
          key={baseKey}
          path={basePath}
          exact={true}
          component={
            existingBaseUrlRecruitment ? lazy(() => import(`../pages/landing-page/rekrutmen/home`)) :
            existingBaseUrlProcurement ? lazy(() => import(`../pages/landing-page/pengadaan/home`)) :
            existingBaseUrlSites ? AuthPage :
            existingBaseUrlVercel ? AuthPage :
            existingBaseUrlOffice ? AuthPage :
            lazy(() => import(`../pages/landing-page/home`))
          }
        /> :
        <Redirect from={basePath} to='/dashboard' />
        // (
        //   isAuthorized?.data?.role[0]?.nama === "Vendor" ?
        //     <Redirect from={basePath} to='/pedoman' /> :
        //     <Redirect from={basePath} to='/dashboard' />
        // )
      }
      
      { !isAuthorized ? 
        <Route
          key='Rekrutmen'
          path='/erecruitment'
          exact={true}
          component={lazy(() => import(`../pages/landing-page/rekrutmen/home`))}
        /> : 
        <Redirect from='/erecruitment' to='/dashboard' />
      }
      
      { !isAuthorized ? 
        <Route
          key='Pengadaan'
          path='/eprocurement'
          exact={true}
          component={lazy(() => import(`../pages/landing-page/pengadaan/home`))}
        /> :
        <Redirect from='/eprocurement' to='/dashboard' />
        // (
        //   isAuthorized?.data?.role[0]?.nama === "Vendor" ?
        //     <Redirect from='/eprocurement' to='/pedoman' /> :
        //     <Redirect from='/eprocurement' to='/dashboard' />
        // )
      }

      { !isAuthorized ? 
        <Route path='/admin' component={AuthPage}/> : 
        <Redirect from='/admin' to='/' />
      }
      { !isAuthorized ? 
        <Route path='/erecruitment/admin' component={AuthPage}/> : 
        <Redirect from='/erecruitment/admin' to='/' />
      }
      { !isAuthorized ?
        <Route path='/eprocurement/admin' component={AuthPage}/> :
        <Redirect from='/eprocurement/admin' to='/' />
      }

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      { !isAuthorized ?
        <Redirect to={basePath} /> : 
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      }
    </Switch>
  )
}

export {Routes}
