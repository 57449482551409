import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props:any) => {
  let config:any = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  if (props.isMobile) {
    config.qrbox= { width: 200, height: 200 }
  }
  return config;
};

export const Html5QrcodePlugin = (props:any) => {
  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    // if (!(props.qrCodeSuccessCallback)) {
    //   // eslint-disable-next-line no-throw-literal
    //   throw "qrCodeSuccessCallback is required callback.";
    // }
    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    function onScanSuccess(decodedText:any, decodedResult:any) {
      // handle the scanned code as you like
      // console.log(`QR matched = ${decodedText}`);
      // console.log(decodedResult);
      // Stop scanning
      // html5QrcodeScanner.clear().then(_ => {
      // }).catch(error => {
      //   // Could not stop scanning for reasons specified in `error`.
      //   // This conditions should ideally not happen.
      // });

      props.setDecodedResults(decodedText, decodedResult)
      // html5QrcodeScanner.pause(true)
    }
    html5QrcodeScanner.render(onScanSuccess, props.qrCodeErrorCallback);
    // html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error:any) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={qrcodeRegionId} />
  );
};
