/* eslint-disable react/jsx-no-target-blank */
// import React from 'react'
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useTranslation } from 'react-i18next'

export function AsideMenuMain(props:any) {
  // const intl = useIntl()
  const { t } = useTranslation();
  // const role = localStorage.getItem('role') !== null ? JSON.parse(localStorage.role)[0]?.nama : null

  const renderMenu = (data:any) => {
    // console.log(data)
    const renderUniqueItem = (param:any) => {
      const {data} = param
      return data.map(function(o:any, i:any) {
        return (
          <div key={`${o.nama}-${i}`}>
            <AsideMenuItem
              to={o.url_front}
              title={o.nama}
              hasBullet
              fontIcon='bi-archive'
            />
          </div>
        )
      });
    }
    const renderMenuItem = (param:any) => {
      const {data, kategori} = param
      return data.map(function(o:any, i:any) {
        return (
          o.type === "parent" ?
            <div key={`${kategori}-${o.type}-${o.kategori}`}>
              <AsideMenuItemWithSub
                to={o.url_front}
                title={o.kategori}
                hasBullet
                fontIcon='bi-archive'
              >
                {renderMenuItem(o)}
              </AsideMenuItemWithSub>
            </div>
            :
            <AsideMenuItem to={o.kelas[0].url_front} title={o.nama} hasBullet={true} key={`${kategori}-${o.type}-${o.kategori}-${o.nama}`} />
        )
      });
    }
    // console.log(data)
    return data.map(function(o:any, i:any) {
      // console.log(i, o)
      // let arr_url = o.data[0].kelas[0].url_front.split('/')
      // arr_url.splice(-1)
      // const final_url = arr_url.join('/')
      // // console.log(final_url)
      return (
        <div key={`mainmenu-${o.kategori}`}>
          {o.kategori === "-" ?
            renderUniqueItem(o) :
            (o.type === "parent" ?
              <AsideMenuItemWithSub
                to={o.url_front}
                title={o.kategori}
                icon='/media/icons/duotune/general/gen008.svg'
                fontIcon='bi-archive'
              >
                {renderMenuItem(o)}
              </AsideMenuItemWithSub> :
              <AsideMenuItem
                to={o.url_front}
                hasBullet
                // icon='/media/icons/duotune/art/art002.svg'
                title={o.kategori}
                fontIcon='bi-app-indicator'
              />
            )
          }
        </div>
      )
    });
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={t`menu.dashboard`}
        fontIcon='bi-app-indicator'
      />
      {/* {role === 'Vendor' &&
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={t`Pedoman`}
          fontIcon='bi-app-indicator'
        />
      } */}
      {renderMenu(props.menu || [])}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{t`menu.master`}</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/master/general'
        title={t`menu.general`}
        icon='/media/icons/duotune/general/gen008.svg'
        fontIcon='bi-archive'
      >
        <AsideMenuItem to='/master/general/user' title={t`user:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/role' title={`Role`} hasBullet={true} />
        <AsideMenuItem to='/master/general/module' title={`Modul`} hasBullet={true} />
        <AsideMenuItem to='/master/general/company' title={t`perusahaan:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/afiliasi' title={t`afiliasi:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/jenis_rekanan' title={t`jenis_rekanan:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/kompetensi_bidang_usaha' title={t`kompetensi_bidang_usaha:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/kompetensi_sub_bidang_usaha' title={t`kompetensi_sub_bidang_usaha:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/region' title={t`regional:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/bank' title={t`bank:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/hubungan_keluarga' title={t`hubungan_keluarga:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/sim' title={t`sim:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/branch' title={t`cabang:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/social_media' title={t`media_sosial:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/recruitment_period' title={t`periode_rekrutmen:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/selection_stage' title={t`tahapan_rekrutmen:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/assessment_material' title={t`materi_penilaian:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/stage_assessment_template' title={t`template_tahapan_penilaian:title`} hasBullet={true} />
        <AsideMenuItem to='/master/general/requirement_type' title={t`jenis_persyaratan:title`} hasBullet={true} />

        <AsideMenuItemWithSub
          to='/master/general/education'
          title={t`menu.pendidikan`}
          hasBullet={true}
          // icon='/media/icons/duotune/general/gen008.svg'
          // fontIcon='bi-archive'
        >
          <AsideMenuItem to='/master/general/education/education_level' title={t`strata_pendidikan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/education_type' title={t`jenis_pendidikan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/school_accreditation' title={t`akreditasi_pendidikan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/master_major' title={t`jurusan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/major_level' title={t`strata_jurusan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/educational_institution' title={t`lembaga_pendidikan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/education_major' title={t`jurusan_pendidikan:title`} hasBullet={true} />
          <AsideMenuItem to='/master/general/education/banpt' title={t`banpt:title`} hasBullet={true} />
        </AsideMenuItemWithSub>

      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{t`menu.sdm`}</span>
        </div>
      </div>
      <AsideMenuItem
        to='/sdm/lowongan'
        title={t`lowongan:title`}
        icon='/media/icons/duotune/general/gen007.svg'
        fontIcon='bi-archive'
      />
      <AsideMenuItem
        to='/sdm/pelamar'
        title={t`pelamar:title`}
        icon='/media/icons/duotune/general/gen006.svg'
        fontIcon='bi-archive'
      />
      <AsideMenuItem
        to='/sdm/penilaian'
        title={t`penilaian:title`}
        icon='/media/icons/duotune/general/gen005.svg'
        fontIcon='bi-archive'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{t`menu.reporting`}</span>
        </div>
      </div> */}

      {/* <AsideMenuItemWithSub
        to='/reporting'
        title={t`menu.reporting`}
        icon='/media/icons/duotune/general/gen008.svg'
        fontIcon='bi-archive'
      >
        <AsideMenuItem to='/reporting/pelamar' title={t`pelamar:title`} hasBullet={true} />
        <AsideMenuItem to='/reporting/penilaian_pelamar' title={t`summary_hasil_penilaian:title`} hasBullet={true} />
        <AsideMenuItem to='/reporting/peminatan_pelamar' title={t`peminatan_pelamar:title`} hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/saran'
        title={t`Saran`}
        icon='/media/icons/duotune/general/gen011.svg'
        fontIcon='bi-archive'
      /> */}
    </>
  )
}
