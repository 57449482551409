export const DashboardVendor = (props:any) => {

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className="col-xxl-12 bg-white">
          <div className="my-8">
            <h1 className="mx-4">Dashboard</h1>
          </div>
        </div>
      </div>
    </>
  );
}