// import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

//i18next translations
import {I18nextProvider} from 'react-i18next';
import i18n from './setup/i18n'

// Apps
import {App} from './app/App'
import * as serviceWorker from './serviceWorkerRegistration'
// import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import { ToastContainer } from 'react-toastify'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

const existingUrl = window.location.href
const baseUrlRecruitment = 'erecruitment.phc.co.id'
const baseUrlProcurement = 'eprocurement.phc.co.id'
if (existingUrl.replace(/https?:\/\//i, "") === baseUrlRecruitment || existingUrl.replace(/https?:\/\//i, "") === baseUrlRecruitment + '/') {
  window.location.replace(window.location.protocol + '//' + baseUrlRecruitment + '/erecruitment')
}  
if (existingUrl.replace(/https?:\/\//i, "") === baseUrlProcurement || existingUrl.replace(/https?:\/\//i, "") === baseUrlProcurement + '/') {
  window.location.replace(window.location.protocol + '//' + baseUrlProcurement + '/eprocurement')
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
        <ToastContainer autoClose={2000}/>
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)
serviceWorker.register();
