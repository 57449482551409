/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
// import {Link} from 'react-router-dom'
// import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
// import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {usePageData} from '../../core/PageData'
import { Div } from './styles'

const Toolbar1: FC = () => {
  const {config, classes} = useLayout()
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()

  const createArrPath = (data:any) => {
    const data_temp = data.split((/(\/)/g))
    let arr: any[] = []
    data_temp.forEach((element:any, index:any) => {
      (index !== 0 && index !== 1 && index !== data_temp.length-1) && arr.push(element)
    });
    return arr
  }

  const getPath = (data:any, index:any, dest:any) => {
    let string = "/"
    for (let i = 0; i <= index; i++) {
      string = string + data[i]
    }
    return string
  }

  return (
    <Div>
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <div
            id='kt_page_title'
            data-kt-swapper='true'
            // data-kt-swapper-mode='prepend'
            // data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
          >
            {/* begin::Title */}
            {pageTitle && (
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
                {pageTitle}
                {pageDescription && config.pageTitle && config.pageTitle.description && (
                  <>
                    <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                    <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                  </>
                )}
              </h1>
            )}
            {/* end::Title */}

            {pageBreadcrumbs &&
              pageBreadcrumbs.length > 0 &&
              config.pageTitle &&
              config.pageTitle.breadCrumbs && (
                <>
                  {config.pageTitle.direction === 'row' && (
                    <span className='h-20px border-gray-200 border-start mx-4'></span>
                  )}
                  <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
                    {/* {Array.from(pageBreadcrumbs).map((item, index) => (
                      <li
                        className={clsx('breadcrumb-item', {
                          'text-dark': !item.isSeparator && item.isActive,
                          'text-muted': !item.isSeparator && !item.isActive,
                        })}
                        key={`${item.path}${index}`}
                      >
                        {!item.isSeparator ? (
                          <Link className='text-muted text-hover-primary' to={item.path}>
                            {item.title}
                          </Link>
                        ) : (
                          <span className='bullet bg-gray-200 w-5px h-2px'></span>
                        )}
                      </li>
                    ))} */}
                    {createArrPath(pageBreadcrumbs[0].path).map((item, index) => (
                      <li
                        className={clsx('breadcrumb-item', {
                          'text-dark': item === "/" && true,
                          'text-muted': item === "/" && false,
                        })}
                        key={`path-${getPath(createArrPath(pageBreadcrumbs[0].path), index, 'key')}`}
                      >
                        {item !== "/" ? (
                          // <Link className='text-muted text-hover-primary' to={getPath(createArrPath(pageBreadcrumbs[0].path), index, 'url')}>
                          //   {item}
                          // </Link>
                          <span className='text-muted text-hover-primary'>{item}</span>                          
                        ) :
                          <span className='bullet bg-gray-200 w-5px h-2px'></span>
                        }
                      </li>
                    ))}
                    <li className='breadcrumb-item text-dark'>{pageTitle}</li>
                  </ul>
                </>
              )}
          </div>
        </div>
        {/* end::Container */}
      </div>
    </Div>
  )
}

export {Toolbar1}
