/* eslint-disable jsx-a11y/anchor-is-valid */
import
// React,
{useEffect} from 'react'
// import {Redirect, Route, Switch} from 'react-router-dom'
// import {Registration} from './components/Registration'
// import {ForgotPassword} from './components/ForgotPassword'
import { Div, BgDiv } from './styles'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Login} from './components/Login'
import { RegistrationRekrutmen } from './components/RegistrationRekrutmen'
import { RegistrationPengadaan } from './components/RegistrationPengadaan'
import { ForgotPassword } from './components/ForgotPassword'
import { useHistory } from 'react-router-dom'

export function AuthPage(props:any) {
  let history = useHistory();

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  
  const back = () => {
    const url = window.location.pathname
    url.includes("admin") ? (
      url.includes("erecruitment") ? history.push('/erecruitment') :
      url.includes("eprocurement") ? history.push('/eprocurement') : history.push('/beranda')
    ) : props.setTabOpen('main')
  }

  return (
    <BgDiv style={{ background: `url(${toAbsoluteUrl('/media/img-dashboard/background-blue2.png ')})` }}>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/img-dashboard/background-blue2.png')})`,
        }}
      >
        {/* begin::Content */}
        <div style={{paddingTop: '2rem'}}>
          <div className='d-flex flex-center flex-column flex-column-fluid pb-lg-20'>
            {/* begin::Logo */}
            <span onClick={() => back()} className='mb-2'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-ihc-text.png')} className='h-100px' />
            </span>
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <Div className='bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              {props.tabOpen === undefined || props.tabOpen === 'register' ?
                window.location.pathname.includes("admin") ? 
                <Login setTabOpen={props.setTabOpen} link_url={window.location.pathname} /> :
                (window.location.pathname.includes("erecruitment") ? 
                <RegistrationRekrutmen setTabOpen={props.setTabOpen} /> :
                <RegistrationPengadaan setTabOpen={props.setTabOpen} />
                ) : 
                props.tabOpen === 'forgot' ?
                <ForgotPassword setTabOpen={props.setTabOpen} link_url={window.location.pathname} /> :
                <Login setTabOpen={props.setTabOpen} link_url={window.location.pathname} />
              }
              {/* <Switch>
                <Route path='/auth/login' component={Login} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch> */}
            </Div>
            {/* end::Wrapper */}
          </div>
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            {/* <a href='#' className='text-muted text-hover-primary px-2'>
              About
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact Us
            </a> */}
          </div>
        </div>
        {/* end::Footer */}
      </div>
    </BgDiv>
  )
}
