export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string, category: string) {
  const current = getCurrentUrl(pathname)
  // console.log(current)
  // console.log(url)
  // console.log(current.indexOf(url))
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    if ((url.match(new RegExp("/", "g")) || []).length === 1) {
      if (current.indexOf(url) === 0) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (category === 'child') {
        return false
      }
      return true
    }
  }

  return false
}
